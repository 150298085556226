<template>
  <en-dialog v-model="visible" width="80%" title="工单搜索">
    <template #operation>
      <en-button type="primary" link @click="operation.export.click">导出</en-button>
    </template>

    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['SVCSTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      code="SERVICE"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
    ></table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: number) => typeof value === 'boolean'
  },

  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.emit('update:model-value', value)
      }
    }
  },

  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/service/export'
              }
            },
            async click() {
              await this.operation.export.get()
            }
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/service',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
